import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Modal } from '.'
import { Filter } from '../Filter'
import { InputsFilter } from '../InputsFilter'
import { Button } from '../Button'

import { useProperties, useMunicipality } from '../../hooks'

import { API_PRIVATE } from '../../service/api'
import { PROPERTIES_ROUTER } from '../../helpers/constants/routes'
import { useEffect } from 'react'

const ModalPreferences = ({ onClose }) => {

    const navigate = useNavigate()
    const {
        dataFilterProperties,
        filterProperties,
    } = useProperties()
    const [loading, setLoading] = React.useState(false)
    const { getDataMunicipality, getOptionForSelect } = useMunicipality()

    const handleFilterProperties = ({ field, value }) => {
        filterProperties({ field, value, onlySetFilter: true })
    }

    const onConfirm = () => {
        setLoading(true)
        try {
            API_PRIVATE({
                endpoint: 'me/preferences',
                method: 'PUT',
                body: { propertiesInterested: { ...dataFilterProperties } },
            })
                .then(() => {

                    const uriFilter = createUriFilter(dataFilterProperties)

                    onClose()
                    navigate(`${PROPERTIES_ROUTER.path}${uriFilter}`)
                })
                .catch((err) => {
                    console.error(err)
                    toast.error('Ha ocurrido realizando la búsqueda, intenta más tarde')
                })
                .finally(() => setLoading(false))
        } catch (error) {
            setLoading(false)
            toast.error('Ha ocurrido realizando la búsqueda, intenta más tarde')
        }
    }

    const createUriFilter = (data) => {
        let uriFilter = '?'
        Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
                uriFilter += `${key}=${data[key].map(i => i.value).join(',')}&`
            }
            if (key === 'price') {
                uriFilter += `${key}=${data[key].min},${data[key].max}&`
            }
            if (key === 'area') {
                uriFilter += `${key}=${data[key].min},${data[key].max}&`
            }
        })

        return uriFilter.slice(0, -1)
    }

    useEffect(() => {
        getDataMunicipality()
    }, [])

    return (
        <Modal
            header='Buscar Propiedad'
            onClose={onClose}
        >
            <div className='modalRegister modal-lg'>
                <small>
                    Indícanos cuál es tu criterio de búsqueda. <br />
                    Te notificaremos cuando encontremos nuevos resultados para ti.
                </small>
                <InputsFilter
                    data={dataFilterProperties}
                    onChangeFilter={handleFilterProperties}
                    optionByMunicipality={getOptionForSelect()}
                />

                <Filter
                    data={dataFilterProperties}
                    onChangeFilter={handleFilterProperties}
                    filterFeatureList={[]}
                    isDisplayed={true}
                    hideOptions={['propertie_code', 'features', 'parking', 'bathrooms', 'rooms', 'administration']}
                />

                <div className='modalTypeUser_btns'>
                    <Button alignSelfStart rounded onClick={onConfirm} pending={loading}>
                        Buscar ahora
                    </Button>

                    <Button
                        textColor='gray'
                        transparent
                        icon
                        underline
                        onClick={onClose}
                        colorIcon='gray'
                        jContentStart
                    >
                        Cancelar
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

ModalPreferences.propTypes = {
    onClose: PropTypes.func,
}

export default ModalPreferences