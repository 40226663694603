import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { Input } from '../../../../components/Input'
import { Button } from '../../../../components/Button'
import PhoneInput from '../../../../components/PhoneInput/PhoneInput'

import { API_PRIVATE } from '../../../../service/api'

const ModifiedUser = ({ user, setUser }) => {

    const [loading, setLoading] = React.useState(false)
    //const [submit, setSubmit] = React.useState(false)
    const [userModified, setUserModified] = React.useState({
        name: user?.name || '',
        email: user?.email || '',
        phoneNumber: user?.phoneNumber || '',
    })

    const handleChange = ({ field, value }) => {
        const update = {
            [field]: value,
        }

        setUserModified({
            ...userModified,
            ...update,
        })
    }

    const handleSubmit = () => {
        try {
            setLoading(true)

            API_PRIVATE({ endpoint: 'set-user', method: 'PUT', body: userModified })
                .then(res => {
                    if (res) setUser(res)
                })
                .catch(() => toast.error('Ha ocurrido un error, intenta más tarde'))
                .finally(() => setLoading(false))
            
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
            <div className="about-input">
                <Input
                    label='Nombre completo'
                    type='text'
                    name="name"
                    value={userModified.name}
                    error={''/*!userModified.name && submit ? 'Campo requerido' : ''*/}
                    disabled={user?.registerMethod === 'social'}
                    onChange={(e) =>
                        handleChange({ field: e.target.name, value: e.target.value })
                    }
                />
            </div>

            <div className="about-input">
                <Input
                    label='Correo electrónico'
                    type='email'
                    name="email"
                    value={userModified.email}
                    disabled={user?.registerMethod === 'email' || user?.registerMethod === 'social'}
                    error={''/*!userModified.email && submit ? 'Campo requerido' : ''*/}
                    onChange={(e) =>
                        handleChange({ field: e.target.name, value: e.target.value })
                    }
                />
            </div>

            <div className="about-input">
                <PhoneInput
                    value={userModified.phoneNumber}
                    disabled={user?.registerMethod === 'phone'}
                    onChange={(e) =>
                        handleChange({ field: 'phoneNumber', value: e })
                    }
                    error={''/*!userModified.phoneNumber && submit ? 'Campo requerido' : ''*/}
                />

                <Button
                    rounded
                    alignSelfStart
                    onClick={handleSubmit}
                    pending={loading}
                >
                    Guardar
                </Button>
            </div>
        </div>
    )
}

ModifiedUser.propTypes = {
    user: PropTypes.object,
    setUser: PropTypes.func,
}

export default ModifiedUser