import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { useLogin } from '../../hooks'
import UserContext from '../../Context/UserContext'
import { API_PRIVATE } from '../../service/api'
import { toast } from 'react-toastify'

// Components
import {
    FeaturedPropertiesDetails,
    FeaturedPropertiesLocation,
    FeaturedPropertiesPrice,
} from '../FeaturedPropertiesList'
import { Text } from '../Text'
import { SavePropertie } from '../SavePropertie'
import { PropertiesInterestedList } from '../PropertiesInterested'

// Routes
import { PROPERTY_ROUTER } from '../../helpers/constants/routes'

const FeaturedPropertiesLarge = ({
    image,
    id,
    details,
    location,
    price,
    name,
    shadow,
    interested,
}) => {
    const { isLogged } = useLogin()

    const classContainer = clsx({
        'propertiesLarge': interested,
    })
    const classFeatured = clsx('featuredPropertiesLarge', {
        'featuredPropertiesLarge_shadow': shadow,
    })

    const { favorites, setFavorites } = React.useContext(UserContext)
    const [loading, setLoading] = React.useState(false)

    const addRemoveToFavorites = async (ev) => {
        try {
            ev.preventDefault()

            if (loading || !id) return
            setLoading(true)

            API_PRIVATE({ endpoint: 'me/remove-favorite', method: 'DELETE', body: { id } }).then(() => {
                setFavorites(favorites.filter(favorite => favorite.id !== id))
            }).catch((err) => {
                console.error(err)
                toast.error('Ha ocurrido un error, intenta más tarde')
            }).finally(() => setLoading(false))
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className={classContainer}>
            <Link to={`${PROPERTY_ROUTER.path}/${id}`} className={classFeatured} style={{ position: 'relative' }}>
                <div className='featuredPropertiesLarge_image'>
                    <img src={image} alt='Images' />
                    { isLogged &&
                    <SavePropertie item={
                        {
                            image,
                            id,
                            details,
                            location,
                            price,
                            name,
                            shadow,
                        }
                    }/>
                    }
                </div>

                <div className='featuredPropertiesLarge_content'>
                    {name && (
                        <Text bold color='primary'>
                            {name}
                        </Text>
                    )}
                    {details && (
                        <FeaturedPropertiesDetails
                            bed={details.bed}
                            bathroom={details.bathroom}
                            meters={details.meters}
                        />
                    )}

                    <FeaturedPropertiesLocation location={location} />

                    <FeaturedPropertiesPrice price={price} />

                    { isLogged &&
                    <button style={{
                        position: 'absolute',
                        right: '30px',
                        top: '40px',
                        padding: '8px 10px',
                        background: 'white',
                        borderRadius: '4px',
                        fontWeight: '600',
                        boxShadow: 'none',
                        border: '1px solid black',
                        cursor: 'pointer',
                        zIndex: '6',
                    }} disabled={loading} onClick={addRemoveToFavorites}>
                        Quitar
                    </button>
                    }
                </div>
            </Link>

            {interested && interested.length > 0 && (
                <PropertiesInterestedList interested={interested} />
            )}
        </div>
    )
}

FeaturedPropertiesLarge.propTypes = {
    image: PropTypes.string,
    id: PropTypes.number,
    details: PropTypes.object,
    location: PropTypes.string,
    price: PropTypes.string,
    name: PropTypes.string,
    shadow: PropTypes.bool,
    favorite: PropTypes.bool,
    interested: PropTypes.array,
}

export default FeaturedPropertiesLarge
