import React, { useState } from 'react'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

// Components
import Logo from '../../../components/Logo/Logo'
import { Title } from '../../../components/Title'
import { Text } from '../../../components/Text'
import {
    Button,
    ButtonFacebook,
    ButtonGoogle,
} from '../../../components/Button'
import { Input, CheckBox } from '../../../components/Input'
import { ModalLogin } from '../../../components/Modal'
import PhoneInput from '../../../components/PhoneInput/PhoneInput'
import { Modal } from '../../../components/Modal'
import ConfirmCode from '../../../components/ConfirmCode/ConfirmCode'

// Cognito
import {
    signIn,
    signUp,
    answerCustomChallenge,
    signInFacebook,
    signInGoogle,
} from '../../../service/AwsCognitoConector'

// Hook
import { useOpenAndCloseHook } from '../../../hooks'

// Images
import RegisterImg from '../../../assets/images/register.jpg'

// Icons
import { ArrowRightIcon } from '../../../components/Svg'

// Helpers
import { validateEmail } from '../../../utils/helpers'

// Field
import {
    FIELD_KEY_EMAIL,
    FIELD_KEY_PHONE,
    FIELD_KEY_PRIVACITY,
} from '../../../utils/fields'

import { DASHBOARD_ROUTER, PRIVACY_POLICY_ROUTER, TERMS_AND_CONDITIONS_ROUTER } from '../../../helpers/constants/routes'

const Register = () => {
    const navigate = useNavigate()
    const [modalLogin, setModalLogin] = useOpenAndCloseHook()
    const [modalOTP, setModalOTP] = useOpenAndCloseHook()

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phone_number, setPhoneNumber] = useState('')
    const [privacity, setPrivacity] = useState(false)
    const [privacityError, setPrivacityError] = useState('')
    const [step, setStep] = useState('phone')
    const [code, setCode] = useState('')
    const [otpError, setOTPError] = useState('')
    const [pending, setPending] = useState(false)
    const [excededAttempts, setExcededAttempts] = useState(false)

    const handleChangeRegisterData = ({ field, value }) => {
        // ! validacion de los inputs
        if (field === FIELD_KEY_EMAIL) {
            if (emailError) setEmailError('')

            return setEmail(value)
        }

        if (field === FIELD_KEY_PHONE) {
            return setPhoneNumber(value)
        }

        if (field === FIELD_KEY_PRIVACITY) {
            if (privacityError) setPrivacityError('')

            return setPrivacity(value)
        }
    }

    const handleRegister = async () => {
        try {
            setEmailError('')

            if (step === FIELD_KEY_EMAIL) {
                if (!validateEmail({ email }))
                    return setEmailError('El correo electrónico no es correcto')
            }

            if (step === FIELD_KEY_PHONE) {
                if (!phone_number) return setEmailError('Ingresa un número de teléfono')
            }

            if (!privacity)
                return setPrivacityError('Debe aceptar los términos y condiciones')

            setPending(true)
            const username = step === 'phone' ? phone_number : email
            try {
                await signUp({ username, deliveryMethod: step })
            } catch (e) {
                console.error(e)
            }

            await signIn({ username })
            setModalOTP(true)
            setPending(false)
        } catch (error) {
            console.error(error)
        } finally {
            setPending(false)
        }
    }

    const handleSuccessConfirmCode = async () => {
        setOTPError('')
        if (excededAttempts) {
            setModalOTP(false)
            return
        }
        try {
            setPending(true)
            const result = await answerCustomChallenge(code)
            if (result === 'failed') {
                setOTPError('El código ingresado es incorrecto')
                return
            }
            setModalOTP(false)
            navigate(`${DASHBOARD_ROUTER.path}?v=tour`)
        } catch (error) {
            setOTPError('Código incorrecto')
            setExcededAttempts(true)
        } finally {
            setPending(false)
        }
    }

    const handleChangeStep = () => {
        setStep(step === 'phone' ? 'email' : 'phone')
        setEmailError('')
    }

    React.useEffect(() => {
        if (privacityError) setPrivacityError('')
    }, [privacity])

    return (
        <>
            <div className='registerPage'>
                <div className='registerPage_img'>
                    <img src={RegisterImg} alt='register' />
                    <Title color='white' customClass='registerPage_img_text' size='lg'>
                        Encuentra tu lugar!
                    </Title>
                </div>

                <div className='registerPage_content'>
                    <div className='registerPage_content_header'>
                        <Logo color='white' />

                        <Button
                            color='white'
                            transparent
                            icon
                            underline
                            onClick={() => setModalLogin(true)}
                        >
                            Iniciar sesión <ArrowRightIcon color='white' />
                        </Button>
                    </div>

                    <div className='registerPage_content_body'>
                        <Title size='md'>
                            Compra y vende tu casa con un agente de confianza
                        </Title>
                        <Text color='gray'>
                            Regístrate para recibir notificaciones de nuevos inmuebles acorde a tus preferencias o publicar tu inmueble en nuestro portal.
                        </Text>

                        <div className='registerPage_content_btnSocial'>
                            <ButtonFacebook onClick={signInFacebook} />
                            <ButtonGoogle onClick={signInGoogle} />
                        </div>

                        <Title size='md' center>
                            O regístrate
                        </Title>

                        <div className='registerPage_content_form'>
                            {step === 'email' && (
                                <Input
                                    label='Correo electrónico'
                                    type='email'
                                    name={FIELD_KEY_EMAIL}
                                    placeholder='Email'
                                    value={email}
                                    error={emailError}
                                    onChange={(e) =>
                                        handleChangeRegisterData({
                                            field: e.target.name,
                                            value: e.target.value,
                                        })
                                    }
                                />
                            )}

                            {step === 'phone' && (
                                <div>
                                    <PhoneInput value={phone_number} onChange={setPhoneNumber} />
                                    {emailError && <p className='inputCustom_error' style={{ marginTop: 10 }}>{emailError}</p>}
                                </div>
                            )}
                            <CheckBox
                                name={FIELD_KEY_PRIVACITY}
                                value={privacity}
                                error={privacityError}
                                onChange={(e) => setPrivacity(e.target.checked)}
                                label={<div>
                                    Acepto las <a href={PRIVACY_POLICY_ROUTER.path} target='_blank' rel='noreferrer'>Políticas de privacidad</a>, <a href={TERMS_AND_CONDITIONS_ROUTER.path} target='_blank' rel='noreferrer'>Términos y condiciones</a> de TierrasGP
                                </div>}
                            />
                            <br />

                            <div className={clsx('dflex', 'justify-between')}>
                                <Button pending={pending} alignSelfStart rounded onClick={handleRegister}>
                                    Registrarme
                                </Button>

                                <Button
                                    textColor='primary'
                                    transparent
                                    icon
                                    underline
                                    onClick={handleChangeStep}
                                    colorIcon='primary'
                                    jContentStart
                                    fontWeight='600'
                                >
                                    {step === 'phone'
                                        ? 'Registrarme con email'
                                        : 'Registrarme con celular'}
                                    <ArrowRightIcon color='primary' />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modalLogin && <ModalLogin close={setModalLogin} />}

            {modalOTP && (
                <Modal header='Código Enviado' onClose={setModalOTP}>
                    <div className='modalLogin'>
                        <Title center size='sm'>
                            Verifica tu cuenta
                        </Title>

                        <ConfirmCode
                            step={step}
                            value={step === FIELD_KEY_PHONE ? phone_number : email}
                            onChange={setCode}
                            pending={pending}
                            error={otpError}
                            excededAttempts={excededAttempts}
                            onSuccess={handleSuccessConfirmCode}
                        />
                    </div>
                </Modal>
            )}
        </>
    )
}

export default Register
