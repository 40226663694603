import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { API_PRIVATE } from '../service/api'

const Context = React.createContext({})

export function UserContextProvider({ children }) {
    const token = null
    const [jwt, setJWT] = useState(() => window.sessionStorage.getItem('jwt') || token)
    const [user, setUser] = useState(null)
    const [pending, setPending] = useState(false)

    // Manage Favorites
    const [favorites, setFavorites] = useState(
        () => [],
    )

    async function getUser() {
        setPending(true)
        API_PRIVATE({ endpoint: 'me' })
            .then(response => {
                if (response) {
                    const { favorites, ...rest } = response
                    setUser(rest)
                    setFavorites(favorites)
                }
            })
            .catch(err => console.error(err))
            .finally(() => setPending(false))
    }

    return (
        <Context.Provider value={{ jwt, pending, setJWT, getUser, user, setUser, favorites, setFavorites }}>
            {children}
        </Context.Provider>
    )
}

UserContextProvider.propTypes = {
    children: PropTypes.node,
}

export default Context
