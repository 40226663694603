import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

// Components
import { Title } from '../../components/Title'
import { Button } from '../../components/Button'
import { Pagination } from '../../components/Pagination'
import { LoadingCardProperty, LoadingFilter } from '../../components/Loading'
import { OrderBy } from '../../components/OrderBy'
import { InputsFilter } from '../../components/InputsFilter'
import { FilterMobile } from '../../components/Filter'
import { ParcellingList } from '../../components/ParcellingList'

// Hook
import { useOpenAndCloseHook, useMunicipality, useParcellings } from '../../hooks'

// Icons
import { ArrowLeftIcon, FilterIcon } from '../../components/Svg'

// Fields
import { FIELD_ORDER_BY, FIELD_PARCELLING } from '../../utils/fields'

// Helpers
import { scrollTop } from '../../helpers/utils'

const Lotes = () => {
    const {
        thereIsFilterUrl,
        listParcellings,
        page,
        totalPage,
        loadingParcellings,
        dataFilterParcellings,
        getParcellings,
        handleChangePageParceling,
        filterParcellings,
        createUriFilter,
    } = useParcellings()
    const { getDataMunicipality, getOptionForSelect } = useMunicipality()

    const [loadingPage, setLoadingPage] = useState(true)
    const [modalFilterTablet, setModalFilterTablet] = useOpenAndCloseHook()
    const isLaptopOrMobile = useMediaQuery({
        query: '(max-width: 1300px)',
    })

    useEffect(() => {
        (async () => {
            try {
                scrollTop()

                const filter = thereIsFilterUrl ? createUriFilter({ datafilter: dataFilterParcellings, page }) : {}

                await Promise.all([getParcellings({ filter }), getDataMunicipality()])
            } catch (error) {
                console.error(error)
            } finally {
                setLoadingPage(false)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFilterProperties = ({ field, value }) => {
        filterParcellings({ field, value })
    }

    return (
        <div className='container'>
            <div className='content propertiesPage'>
                <div className='propertiesPage_filter'>
                    <div className='propertiesPage_filter_title'>
                        <ArrowLeftIcon />
                        <Title size='lg'>Parcelación</Title>
                    </div>

                    {!loadingPage ? (
                        <>
                            <InputsFilter
                                data={dataFilterParcellings}
                                onChangeFilter={handleFilterProperties}
                                optionByMunicipality={getOptionForSelect()}
                                hiddenType
                                addInputCode={{
                                    name: FIELD_PARCELLING.code,
                                    label: 'Código de parcelación',
                                }}
                            />
                        </>
                    ) : (
                        <LoadingFilter />
                    )}
                </div>
                <div className='propertiesPage_content'>
                    <div className='propertiesPage_content_header'>
                        <div className='propertiesPage_filter_title propertiesPage_title_mobile'>
                            <ArrowLeftIcon />
                            <Title size='md'>Parcelación</Title>
                        </div>

                        <div className='propertiesPage_content_header_btn'>
                            {isLaptopOrMobile && (
                                <Button
                                    color='secundary'
                                    rounded
                                    transparent
                                    textColor='primary'
                                    icon
                                    className='propertiesPage_content_filter'
                                    onClick={setModalFilterTablet}
                                >
                                    <FilterIcon />
                                </Button>
                            )}

                            <OrderBy
                                mobile={isLaptopOrMobile}
                                orderBy={dataFilterParcellings[FIELD_ORDER_BY]}
                                onChangeFilter={handleFilterProperties}
                            />
                        </div>
                    </div>
                    <div className='propertiesPage_content_list'>
                        {loadingPage || loadingParcellings ? (
                            <LoadingCardProperty count={4} />
                        ) : (
                            <>
                                {listParcellings.data.length > 0 && (
                                    <>
                                        <ParcellingList data={listParcellings.data} />
                                        <Pagination
                                            className='propertiesPage_pagination'
                                            page={Number(page)}
                                            countPage={Number(totalPage)}
                                            onChangePage={handleChangePageParceling}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {isLaptopOrMobile && (
                <>
                    {modalFilterTablet && (
                        <FilterMobile
                            close={setModalFilterTablet}
                            mobileHiddenOverFlow
                        >
                            <InputsFilter
                                data={dataFilterParcellings}
                                onChangeFilter={handleFilterProperties}
                                optionByMunicipality={getOptionForSelect()}
                                hiddenType
                                addInputCode={{
                                    name: FIELD_PARCELLING.code,
                                    label: 'Código de parcelación',
                                }}
                            />
                        </FilterMobile>
                    )}
                </>
            )}
        </div>
    )
}

export default Lotes